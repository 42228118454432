import React, { FC } from 'react'
import { PageTemplate } from '../components/pageTemplate'
import { PostHeader } from '../components/postHeader'

// http://www.dktech.cz/?lang=cz&paged=12
// http://www.dktech.cz/?p=149#more-149

const PostHlasovaniStehovaniServeru: FC = () => {
  return (
    <PageTemplate
      prevLink="/post_vyberove_rizeni"
      nextLink="/post_c4"
    >
      <PostHeader
        title="Informace o hlasování hráčů a stěhování serveru"
        link="/post_hlasovani_stehovani_serveru"
        author="Algy"
        date="17.01.2014"
        time="8:02"
        category="Oznámení"
      />
      <p>Zdravím,</p>
      <p style={{ textAlign: 'justify' }}>po více než měsíci od prvních informací vám mohu konečně oznámit, že dne 15. ledna 2014 úspěšně proběhlo přestěhování serveru na virtuální server. Bylo by na místě říci, co nám to přineslo. V první řadě byla databáze přenesena na nový hardware. Ten starý už skutečně dosluhuje. Hned v druhé řadě to znamená, že na dlouhou dobu máme připojení i provoz serveru zdarma. Velké dík patří novým poskytovatelům, ale i lidem, kteří se bez ohledu na vánoční čas na akci stěhování podíleli.</p>
      <p style={{ textAlign: 'justify' }}><span id="more-149"></span></p>
      <p style={{ textAlign: 'justify' }}>Právě úspěšným přenesením databáze na virtuální server bylo podmíněno ukončení hlasování hráčů o tom, kterým směrem bude server DKTech.cz pokračovat. Ankety dostupné na Facebooku se zúčastnilo celkem 283 hlasujících. Na výběr bylo z možností, aby po zajištění virtuální serveru byla na DKTechu spuštěna:</p>
      <p>1/ Gracia Final s původní databází postav (od roku 2011),<br />
        2/ Gracia Final s novou databází (wipe),<br />
        3/ C4 – Scions of Destiny s databází od spuštění (24. 12. 2013).</p>
      <p style={{ textAlign: 'justify' }}>Pro možnost 1. hlasovalo 6 hráčů (2,1%), pro možnost 2. hlasovalo 17 hráčů (6%) a 3. možnost hlasovalo neuvěřitelných 260 (91,9%). Poslední z nabízených možností převážila nad ostatními tak drtivě, že není pochyb, co si přejete. Na fórech se následně rozhořely diskuze v nichž padlo spousty názorů ta výši rate, počáteční hotovosti, nastevení newbie buffů a spousty dalších. Oznamujeme vám, že takové diskuze jsou nadále bezpředmětné, slíbili jsme, že server poběží v tomtéž nastavení, v jakém byl na Štědrý den spuštěn. A tento slib hodláme dodržet.</p>
      <p style={{ textAlign: 'justify' }}>Pro server DKTech.cz byl hráčem Treeman vytvořen <span style={{ color: '#0000ff' }}><a href="https://www.youtube.com/watch?v=uGMWzXi6l9U" target="_blank"><span style={{ color: '#0000ff' }}>trailer</span></a>.</span> Díky.</p>
      <p style={{ textAlign: 'justify' }}>Na tomto místě bych chtěl za celé vedení serveru všem aktivním hráčům poděkovat a popřát vám příjemné hraní, spousty nově navázaných herních i skutečných přátelství a hodiny dobré zábavy.</p>
      <p style={{ textAlign: 'justify' }}>GM Algy</p>
      <div className="cleared"></div>
      <span className="linkpages"></span><div className="cleared"></div>

      <div className="cleared"></div>
    </PageTemplate>
  )
}

export default PostHlasovaniStehovaniServeru

export { Head } from '../components/defaultHtmlHead'